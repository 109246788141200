<template>
  <h1></h1>
</template>

<script>
export default {
  name: 'NotFoundPage',
  data: () => ({}),
  created() {
    this.$router.push({ name: 'HomeCalendarView' });
  },
};
</script>
